import Image, { ImageProps } from "next/image";
import styles from "./index.module.scss";
import React, { FC } from "react";
import { StaticImport } from "next/dist/shared/lib/get-img-props";
import Head from "next/head";
interface IPicture extends Omit<ImageProps, "src"> {
  desktop: string | StaticImport;
  tablet?: string;
  mobile: string;
  alt: string;
  width: number | `${number}`;
  height: number | `${number}`;
  className?: string;
  name?: string;
  src?: string;
}
const Picture: FC<IPicture> = props => {
  const {
    desktop = "",
    tablet = "",
    mobile = "",
    alt = "",
    width,
    height,
    className = "",
    name = "",
    priority,
    ...rest
  } = props;
  const shouldPreload = priority === true;
  return <>
      {shouldPreload && <Head>
          <link rel="preload" as="image" href={mobile} media="(max-width: 767.98px)" />
        </Head>}
      <picture className={styles.picture} data-sentry-element="picture" data-sentry-source-file="index.tsx">
        {mobile && <source media="(max-width: 767.98px)" srcSet={mobile} />}
        {tablet && <source media="(max-width: 1365.98px)" srcSet={tablet} />}
        <Image {...rest} src={desktop} alt={alt} width={width} height={height} className={className} priority={priority} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
      </picture>
    </>;
};
export default Picture;