"use client";

import styles from "./index.module.scss";
import { memo, useEffect, useState } from "react";
import { getNoun } from "@/utils/functions/getNoun";
interface INewTimer {
  activeTo: string;
}
const calculateTimeLeft = (endTime: string): {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
} => {
  const end = new Date(endTime).getTime();
  const now = new Date().getTime();
  const difference = end - now;
  if (difference <= 0) {
    return {
      days: "00",
      hours: "00",
      minutes: "00",
      seconds: "00"
    };
  }
  const days = String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(2, "0");
  const hours = String(Math.floor(difference % (1000 * 60 * 60 * 24) / (1000 * 60 * 60))).padStart(2, "0");
  const minutes = String(Math.floor(difference % (1000 * 60 * 60) / (1000 * 60))).padStart(2, "0");
  const seconds = String(Math.floor(difference % (1000 * 60) / 1000)).padStart(2, "0");
  return {
    days,
    hours,
    minutes,
    seconds
  };
};
const TimerBlock = memo(({
  value,
  label
}: {
  value: string;
  label: string;
}) => <div className={styles.timerItemContainer}>
    <div className={styles.timerTimeContainer}>
      <div className={styles.timerItem}>{value[0]}</div>
      <div className={styles.timerItem}>{value[1]}</div>
    </div>
    <p className={styles.timerItemText}>{label}</p>
  </div>);
const NewTimer = (props: INewTimer) => {
  const {
    activeTo
  } = props;
  const [timeLeft, setTimeLeft] = useState(() => calculateTimeLeft(activeTo));
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(calculateTimeLeft(activeTo));
    }, 1000);
    return () => clearInterval(intervalId);
  }, [activeTo]);
  return <div className={styles.timer} data-sentry-component="NewTimer" data-sentry-source-file="index.tsx">
      <div className={styles.timerItems}>
        <TimerBlock value={timeLeft.days} label={getNoun(Number(timeLeft.days), "день", "дня", "дней")} data-sentry-element="TimerBlock" data-sentry-source-file="index.tsx" />
        <TimerBlock value={timeLeft.hours} label={getNoun(Number(timeLeft.hours), "час", "часа", "часов")} data-sentry-element="TimerBlock" data-sentry-source-file="index.tsx" />
        <TimerBlock value={timeLeft.minutes} label={getNoun(Number(timeLeft.minutes), "минута", "минуты", "минут")} data-sentry-element="TimerBlock" data-sentry-source-file="index.tsx" />
        <TimerBlock value={timeLeft.seconds} label={getNoun(Number(timeLeft.seconds), "секунда", "секунды", "секунд")} data-sentry-element="TimerBlock" data-sentry-source-file="index.tsx" />
      </div>
    </div>;
};
export default NewTimer;