"use client";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { ICardMini } from "@/shared/types/types";
import CardMini from "@/shared/components/CardMini";
import "swiper/scss/pagination";
import "swiper/scss";
import "./swiperStyles.scss";
interface IHitsList {
  items: Array<ICardMini>;
  onInit: (state: boolean) => void;
}
const WeekList = (props: IHitsList) => {
  const {
    items,
    onInit
  } = props;
  const breakpoints = {
    1441: {
      spaceBetween: 40,
      slidesPerView: 5
    },
    1025: {
      spaceBetween: 35,
      slidesPerView: 4
    },
    900: {
      spaceBetween: 35,
      slidesPerView: "auto"
    },
    441: {
      spaceBetween: 35,
      slidesPerView: "auto"
    },
    1: {
      spaceBetween: 4,
      slidesPerView: 2
    }
  };
  return <Swiper className={"swiper-week-items"} modules={[Pagination, Autoplay]} pagination={{
    clickable: true
  }} slidesPerView={"auto"} autoplay={{
    delay: 5000,
    disableOnInteraction: false
  }} loop={true} onInit={() => onInit(true)} data-sentry-element="Swiper" data-sentry-component="WeekList" data-sentry-source-file="index.tsx">
      {items.map((item: ICardMini, index: number) => {
      return <SwiperSlide key={`${index + "swiperSlide" + "WeekItems"}`}>
            <CardMini card={item} />
          </SwiperSlide>;
    })}
    </Swiper>;
};
export default WeekList;