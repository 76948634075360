"use client";

import styles from "./index.module.scss";
import WeekList from "@/primary/components/WeekList";
import { ICardMini } from "@/shared/types/types";
import { useState } from "react";
import SkeletonLoader from "@/shared/components/skeleton-loader";
import NewTimer from "@/primary/components/NewTimer";
interface IWeekItems {
  hits: {
    element: {
      active_to: string;
    };
    items: ICardMini[];
  };
}

// Отдельный компонент для таймера, чтобы отделить его рендеринг
const TimerSection = ({
  activeTo
}: {
  activeTo: string;
}) => <div className={styles.timer} data-sentry-component="TimerSection" data-sentry-source-file="index.tsx">
    <div className={styles.timerTextContainer}>
      <h2 className={styles.timerTitle}>товары недели</h2>
      <p className={styles.timerText}>До конца действия акции осталось:</p>
    </div>
    <NewTimer activeTo={activeTo} data-sentry-element="NewTimer" data-sentry-source-file="index.tsx" />
  </div>;
const WeekItems = (props: IWeekItems) => {
  const {
    hits
  } = props;
  const [isInit, setInit] = useState<boolean>(false);
  if (!hits || !hits.items.length || !hits.element || !hits.element.active_to) return null;
  return <section className={styles.wrap} data-sentry-component="WeekItems" data-sentry-source-file="index.tsx">
      {!isInit ? <SkeletonLoader borderRadius={24} containerTag={"div"} className={styles.loader} height={""} width={""} /> : null}
      <div className={styles.top}>
        <h2 className={styles.title}>товары недели</h2>
        <TimerSection activeTo={hits.element.active_to} data-sentry-element="TimerSection" data-sentry-source-file="index.tsx" />
      </div>
      <WeekList items={hits.items} onInit={setInit} data-sentry-element="WeekList" data-sentry-source-file="index.tsx" />
    </section>;
};
export default WeekItems;